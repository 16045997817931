import React from 'react'
import { Link } from "gatsby"
import PropTypes from "prop-types"

const Footer = ({siteTitle}) => {
	return (
		<footer className="bg-blue-800">
			<div className="container-wide border-t border-gray-400 text-white p-5">
				<div className="text-center">
					<div className="font-semibold text-gray-400 mb-2">&copy; Copyright 2020. {siteTitle}</div>
					<div className="mb-1"><Link to="/privacy-policy" className="font-semibold text-white hover:text-purple-800">Privacy Policy</Link></div>
					<div className="mb-1"><Link to="/terms-of-service" className="font-semibold text-white hover:text-purple-800">Terms of Service</Link></div>
				</div>
			</div>
		</footer>
	)
}

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

export default Footer