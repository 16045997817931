import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Link as ScrollLink } from "react-scroll"

const Header = ({ siteTitle }) => {
	const data = useStaticQuery(graphql`
		query Logo{
			logo: file(relativePath: { eq: "go3resv-logo.png" }) {
				childImageSharp {
					fixed(width: 200,) {
						...GatsbyImageSharpFixed
					}
				}
			}

			logoW: file(relativePath: { eq: "go3resv-logo.png" }) {
				childImageSharp {
					fixed(width: 200, duotone: { highlight: "#ffffff", shadow: "#ffffff" } ) {
						...GatsbyImageSharpFixed
					}
				}
			}
		}
	`)

	function toggleMobileNav() {
		let nav = document.querySelector('#nav')

		if (nav.classList.contains('hidden')) {
			nav.classList.remove('hidden')
		} else {
			nav.classList.add('hidden')
		}
	}

	return(
		<header className="absolute top-0 left-0 z-50 w-full">
			<div className="container-wide">
				<nav className="flex items-center justify-between flex-wrap px-4 py-6">
					<Link to="/" className="logo-white">
						<Img fixed={data.logoW.childImageSharp.fixed} alt={siteTitle}/>
					</Link>

					<Link to="/" className="logo-colored hidden">
						<Img fixed={data.logo.childImageSharp.fixed} alt={siteTitle}/>
					</Link>

					<div className="block lg:hidden">
						<button onClick={e => toggleMobileNav()} className="flex items-center px-3 py-2 border rounded text-white border-white hover:text-purple-300 hover:border-purple-300">
							<svg className="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title>
								<path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/>
							</svg>
						</button>
					</div>

					<div id="nav" className="hidden w-full text-white text-lg bg-purple-800 p-4 lg:p-0 lg:bg-transparent lg:block lg:flex lg:items-center lg:w-auto">
						<ScrollLink to="features" smooth={true} duration={500} 
							className="uppercase cursor-pointer flex-auto font-semibold block mb-4 lg:mb-0 lg:inline lg:mr-6">features</ScrollLink>
						<ScrollLink to="business" smooth={true} duration={500} 
							className="uppercase cursor-pointer flex-auto font-semibold block mb-4 lg:mb-0 lg:inline lg:mr-6">business</ScrollLink>
						<ScrollLink to="pricing" smooth={true} duration={500} 
							className="uppercase cursor-pointer flex-auto font-semibold block mb-4 lg:mb-0 lg:inline lg:mr-6">pricing</ScrollLink>
						<ScrollLink to="tour" smooth={true} duration={500} 
							className="uppercase cursor-pointer flex-auto font-semibold block mb-4 lg:mb-0 lg:inline lg:mr-6">quick tour</ScrollLink>
						<ScrollLink to="faq" smooth={true} duration={500} 
							className="uppercase cursor-pointer flex-auto font-semibold block mb-4 lg:mb-0 lg:inline lg:mr-6">faq</ScrollLink>
						<ScrollLink to="contact" smooth={true} duration={500} 
							className="uppercase cursor-pointer flex-auto font-semibold block lg:inline">contact</ScrollLink>
					</div>
				</nav>
			</div>
		</header>
	)
}

Header.propTypes = {
	siteTitle: PropTypes.string,
}

export default Header
